<template>
  <WeContainer card="" v-if="ready">
    <Navigation
      v-on:save-form="submitForm"
      v-bind:submit="submit"
      v-bind:errors="errors"
    />
    <div class="tab-content">
      <div class="tab-pane fade show active">
        <div class="row align-items-stretch mb-xl-3">
          <div class="col-12 mb-3 col-xl mb-xl-0">
            <div class="card h-100">
              <div class="card-body">
                <div class="form-row mb-3">
                  <div class="col-12 col-md">
                    <!-- Title -->
                    <WeInput
                      class="mb-0"
                      label="Başlık"
                      v-model="form.title"
                      v-bind:error="$v.form.title.$error"
                    />
                    <!-- ./Title -->
                  </div>
                  <div class="col-12 col-md">
                    <!-- Location -->
                    <label class="custom-label">Konum</label>
                    <v-select
                      v-bind:options="locationOptions"
                      label="name"
                      v-bind:reduce="(item) => item.id"
                      v-model="form.location"
                    ></v-select>
                    <!-- ./Location -->
                  </div>
                </div>

                <!-- URL -->
                <WeInput label="Yönledirilecek URL" v-model="form.url" />
                <!-- ./URL -->

                <!-- Start & End Date -->
                <div class="form-row">
                  <div class="col-12 col-md">
                    <label class="custom-label">Başlangıç Tarihi</label>
                    <WeDatePicker
                      class="mb-3"
                      id="start-date"
                      label="Tarih Seçin"
                      v-bind:format="'DD.MM.YYYY HH:mm'"
                      v-bind:output-format="'YYYY-MM-DD HH:mm'"
                      v-bind:locale="'tr'"
                      v-bind:no-label="true"
                      v-bind:no-button-now="true"
                      v-bind:no-clear-button="false"
                      v-model="form.start_date"
                    />
                  </div>
                  <div class="col-12 col-md">
                    <label class="custom-label">Bitiş Tarihi</label>
                    <WeDatePicker
                      id="end-date"
                      label="Tarih Seçin"
                      v-bind:format="'DD.MM.YYYY HH:mm'"
                      v-bind:output-format="'YYYY-MM-DD HH:mm'"
                      v-bind:locale="'tr'"
                      v-bind:no-label="true"
                      v-bind:no-button-now="true"
                      v-bind:no-clear-button="false"
                      v-model="form.end_date"
                    />
                  </div>
                </div>
                <!-- ./Start & End Date -->

                <div class="form-group">
                  <label class="custom-label">Durum</label>
                  <WeSwitch
                    v-model="form.is_active"
                    v-bind:show-status="true"
                  />
                </div>

                <!-- Content -->
                <div class="row">
                  <div class="col">
                    <label
                      class="custom-label"
                      v-bind:class="{ 'text-danger': $v.form.content.$error }"
                      >İçerik</label
                    >
                  </div>
                  <div class="col-auto" v-if="$v.form.content.$error">
                    <small class="text-danger"><i>Zorunlu Alan</i></small>
                  </div>
                </div>
                <WeEditor
                  name="pop-up"
                  v-bind:accessToken="session.accessToken"
                  v-model="form.content"
                />
                <!-- ./Content -->

                <!-- Condition -->
                <ul
                  class="list-group mb-3"
                  v-bind:class="{
                    'border border-danger': $v.form.condition.$error,
                  }"
                >
                  <li
                    class="list-group-item"
                    v-for="(condition, index) in conditions"
                    v-bind:key="index"
                  >
                    <div class="custom-control custom-radio">
                      <input
                        type="radio"
                        class="custom-control-input"
                        name="pop-up-condition"
                        v-bind:id="'pop-up-' + index"
                        v-bind:checked="condition.name == form.condition"
                        v-on:change="form.condition = condition.name"
                      />
                      <label
                        class="custom-control-label"
                        v-bind:for="'pop-up-' + index"
                      >
                        <div class="row align-items-center">
                          <div
                            class="col"
                            v-if="condition.name == 'after_seconds'"
                          >
                            <WeInput
                              style="width: 50px"
                              class="mb-0"
                              v-model="form.condition_value"
                              placeholder="X"
                              small-size="1"
                              v-bind:filter-number="true"
                              v-bind:error="$v.form.condition_value.$error"
                            />
                          </div>
                          <div
                            class="col-auto"
                            v-bind:class="{
                              'pl-md-0': condition.name == 'after_seconds',
                            }"
                          >
                            {{ condition.label }}
                          </div>
                        </div>
                      </label>
                    </div>
                  </li>
                </ul>
                <!-- ./Condition -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
const Navigation = () => import("./views/Navigation/Index");

export default {
  name: "Detail",
  data() {
    return {
      ready: false,
      submit: false,
      errors: [],
      locationOptions: [
        {
          id: "home",
          name: "Ana Sayfa",
        },
        {
          id: "cart",
          name: "Sepet",
        },
        {
          id: "after_register",
          name: "Üye olduktan sonra",
        },
        {
          id: "after_login",
          name: "Giriş yaptıktan sonra",
        },
      ],
      conditions: [
        {
          name: "on_load",
          label: "Site açılınca göster",
        },
        {
          name: "after_seconds",
          label: "saniye sonra göster",
        },
        {
          name: "before_leaving",
          label: "Çıkış yaparken göster",
        },
      ],
      form: {
        title: null,
        content: null,
        url: null,
        location: "home",
        start_date: null,
        end_date: null,
        condition: null,
        condition_value: null,
        is_active: true,
      },
      cleanData: {},
      cleanRequest: {},
    };
  },
  components: { Navigation },
  validations: {
    form: {
      title: {
        required,
      },
      content: {
        required,
      },
      location: {
        required,
      },
      condition: {
        required,
      },
      condition_value: {
        required: requiredIf(function (data) {
          return this.form.condition == "after_seconds";
        }),
      },
    },
  },
  methods: {
    ...mapActions("popup", ["create", "update", "getById"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;

      if (this.$route.params.id) {
        this.cleanRequest = helper.difference(this.form, this.cleanData);
        this.updatePopup();
      } else {
        this.addPopup();
      }
    },
    addPopup() {
      this.create({
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Popup listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Detaya Git",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                this.$router.push(`detail/${result.data.id}`);
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updatePopup() {
      this.update({
        id: this.$route.params.id,
        form: this.cleanRequest,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Popup listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Sayfada Kal",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                window.location.reload();
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    redirectToList() {
      this.$router.push("/pop-ups");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    getPopupById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              let resultData = result.data.item;
              if (resultData) {
                resultData.is_active =
                  resultData.is_active === 1 ? true : false;
              }

              this.form = resultData;
              this.cleanData = { ...this.form };
            }
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
  },
  computed: {
    ...mapState(["session", "shared"]),
    getTitle() {
      return this.$route.params.id ? "POP-UP DÜZENLE" : "POP-UP EKLE";
    },
  },
  mounted() {
    this.getPopupById();
  },
};
</script>
